import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'KUKU-BUSD LP',
    lpAddresses: {
      97: '0x564a5d65c97083353e02F9F2156883fcB98e36A0',
      56: '0xa992242749981Dd1b188AFD765D4710EEddA136e',
    },
    tokenSymbol: 'KUKU',
    tokenAddresses: {
      97: '0xF658676285ee9995d3E70809730e509108acb3F1',
      56: '0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'KUKU-BNB LP',
    lpAddresses: {
      97: '0x1D8D030A8E18cb34A5d07e5Edd5e113ad7205487',
      56: '0x9B6742066BE28C12402c892d4C7c5bd585F14Ddd',
    },
    tokenSymbol: 'KUKU',
    tokenAddresses: {
      97: '0xF658676285ee9995d3E70809730e509108acb3F1',
      56: '0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'KUKU-BUSDT LP',
    lpAddresses: {
      97: '0x564a5d65c97083353e02F9F2156883fcB98e36A0',
      56: '0x8460692363d3aBb6dC6feE0Eb9782909751402d6',
    },
    tokenSymbol: 'KUKU',
    tokenAddresses: {
      97: '0xF658676285ee9995d3E70809730e509108acb3F1',
      56: '0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
    },
    quoteTokenSymbol: QuoteToken.BUSDT,
    quoteTokenAdresses: contracts.busdt,
  },
  {
    pid: 3,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'KUKU',
    lpAddresses: {
      97: '0x564a5d65c97083353e02F9F2156883fcB98e36A0',
      56: '0xa992242749981Dd1b188AFD765D4710EEddA136e', // CAKE-BUSD LP
    },
    tokenSymbol: 'KUKU',
    tokenAddresses: {
      97: '0xF658676285ee9995d3E70809730e509108acb3F1',
      56: '0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
]

export default farms
