import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
      font-family: 'Poppins', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  *::-webkit-scrollbar {
    width: 5px;
  }
  *::-webkit-scrollbar-thumb {
    background: #46d5db;
    border-radius: 8px;
  }
  .CookieConsent {
    width: 400px !important;
    max-height: 500px !important;
    background-color: #46d5db !important;
    z-index: 10000 !important;
    position: fixed !important;
    right: 30px !important;
    bottom: 30px !important;
    padding: 20px 25px !important;
    box-shadow: 0 2px 6px 0px rgb(0 0 0 / 30%) !important;
    border-radius: 7px !important;
    left : unset !important;
 }
.CookieConsent h3 {
  margin: 0 0 10px;
}
  .home_page {
    background: url("../images/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .img_row img {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    height: 56px;
    width: auto;
  }
  .AvenirNextBold,
  .hvd_comicfont div {
    font-weight: 400;
    line-height: 1.1;
  }
  h2.hvd_comicfont {
    line-height: 0 !important;
    margin: 0;
  }
  .dash_heading {
    font-size: 38px;
    font-weight: 700;
    color: #e69f35;
  }
  .dash_subheading {
    font-size: 16px;
    color: #fff;
    padding: 0 150px;
    font-weight: 400;
  }
  .dash_subheading span {
    font-weight: 500;
    color: #e69f35;
  }
  .white_box {
    box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 20%) !important;
    border-radius: 10px !important;
  }
  .vision_card_panel {
    margin: 10px auto 0;
    padding: 20px 0;
  }
  button {
    outline: none !important;
  }
  .header_btn {
    padding: 10px 20px;
    font-size: 16px;
    box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset !important;
    height: 40px !important;
    background-color: #e69f35 !important;
    color: #fff;
    letter-spacing: 0px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  .toggle_wrapper > div:nth-child(2) {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    border-radius: 30px;
  }
  .toggle_wrapper > div div:first-child {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  }
  .toggle_wrapper > div div:first-child div {
    background-color: #46d5db;
    box-shadow: none !important;
  }
  button.farms_unlock_btn {
    width: 100%;
    height: 40px;
    background-color: #e69f35 !important;
    border: 1px solid #e69f35;
    color: #fff !important;
    border-radius: 5px;
    outline: none !important;
  }
  button.toggle_btn {
    box-shadow: none !important;
  }
  button {
    box-shadow: none !important;
  }
  .carousel_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 88%;
  }
  .carousel_content h2 {
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
  }
  .carousel_content p {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #000000;
  }
  .carousel-control-prev-icon {
    background-image: url("../images/prev_icon.png");
  }
  .carousel-control-next-icon {
    background-image: url("../images/next_icon.png");
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 15px;
    height: 26px;
  }
  .carousel-item img {
    margin-left: 50px;
    width: 220px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    opacity: 0.8;
  }
  .carousel-control-prev {
    left: -50px;
  }
  .carousel-control-next {
    right: -50px;
  }
  .dash_head_1 {
    font-size: 24px;
    font-weight: 700;
    color: #29292a;
  }
  button.cap_btn {
    background-color: #e69f35;
    border-radius: 6px;
    font-size: 16px;
    padding: 10px 20px;
    height: auto;
  }
  button.stake_btn {
    background-color: #e69f35;
    border-radius: 6px;
    font-size: 16px;
    padding: 10px 20px;
    height: auto;
  }
  .stats_box h2 {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 12px;
  }
  .white_box.stats_box {
    background: #fff;
    border: 2px solid #3ccbd1;
  }
  .stats_box_row div:nth-child(2) {
    color: #000;
  }
  .home_nft_box {
    background-color: #a25341;
  }
  .home_nft_box h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .home_nft_box p {
    font-size: 16px;
    color: #ddd;
    line-height: 30px;
  }
  .home_nft_box button {
    background: #e69f35;
    border-radius: 5px;
    font-size: 16px;
    text-transform: uppercase;
    height: auto;
    padding: 12px 25px;
    margin-top: 50px;
  }
  .home_lottery_box_right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .home_lottery_box_right h3 {
    font-size: 24px;
    color: #010000;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 20px;
  }
  .home_lottery_box_right h1 {
    font-size: 50px;
    color: #fff;
    font-weight: 900;
  }
  .home_lottery_box_right p {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: left;
    line-height: 24px;
  }
  .home_lottery_box_right button {
    background: #e69f35;
    border-radius: 8px;
    font-size: 16px;
    text-transform: uppercase;
    height: auto;
    padding: 15px 40px;
  }
  hr.custom_hr {
    border-top: 1px solid #d0f7f9;
    width: 100%;
    margin-top: 5px;
  }
  .home_farm_box h2 {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 12px;
    font-size: 24px;
    font-weight: 700;
    color: #29292a;
  }
  .home_farm_box {
    background-color: #fff;
  }
  .home_farm_box button {
    width: 200px;
  }
  .farms_label {
    color: #e69f35 !important;
    font-size: 24px !important;
    font-weight: 700;
  }
  .farms_bal_val {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 5px;
  }
  .home_farm_box h5 {
    color: #626262;
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0;
  }
  .farms_right_box {
    margin-left: 12%;
    border-left: 1px solid #dfdfdf;
    padding-left: 50px;
  }
  .home_total_box h3 {
    color: #29292a;
    font-size: 24px;
    font-weight: 700;
  }
  .home_total_box h2 div {
    color: #ffffff;
    font-size: 54px;
    font-weight: 900;
  }
  .home_total_box h5 {
    color: #29292a;
    font-size: 14px;
    font-weight: 600;
  }
  a:hover {
    text-decoration: none !important;
  }
  .menulink:hover {
    background-color: #e69f35;
  }
  a:hover {
    color: #e69f35 !important;
  }
  button.apy_btn {
    background: #053032 !important;
    margin-right: 8px;
  }
  button.close_btn {
    background: transparent !important;
  }
  button.wallet_btn {
    background-color: #fff;
    border-radius: 5px;
  }
  button.wallet_btn:hover {
    background-color: #e69f35 !important;
  }
  h1.inner_heading {
    font-weight: 700;
    color: #e69f35;
  }
  .fa-copy {
    font-size: 18px;
    margin-left: 5px;
    color: #46d5db;
    cursor: pointer;
  }
  .carousel_card {
    background-color: transparent;
    box-shadow: none !important;
  }
  .carousel-item {
    // padding: 24px;
    border-radius: 10px;
  }
  .carousel-item > .row {
    height: 100%;
    align-items: center;
    justify-content: space-between;
    min-height: 310px;
  }
  .carousel_item_one {
    background-color: #70e3ea;
  }
  .carousel_item_two {
    background-color: #70e3ea;
  }
  .carousel_item_three {
    background-color: #000422;
  }
  .carousel_item_four {
    background-color: #110145;
  }
  .carousel_item_three .carousel_content p,
  .carousel_item_four .carousel_content p {
    color: #fff
  }



  @media screen and (max-width: 799px) {
    .dash_subheading{
      padding: 0 15px;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 559px) {
    .carousel_content{
      width: 100%;
    }
    .carousel_content h2{
      text-align: center;
    }
    .carousel_content p{
      text-align: center;
    }
    .carousel-item img{
      margin-left: 0;
      margin-bottom: 15px;
    }
    .toggle_wrapper {
      display: block !important;
      text-align: center;
    }
    .toggle_wrapper > div {
      margin-bottom: 15px;
    }
    .carousel-control-prev{
      left: -25px;
    }
    .carousel-control-next{
      right: -25px;
    }
    .farms_right_box{
      padding-left: 0;
      margin-left: 0;
      margin-top: 15px;
    }
  }
`

export default GlobalStyle
